@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "sf_mono";
    src: url('./fonts/SF-Mono.dmg');
}

@import url('https://fonts.googleapis.com/css2?family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&display=swap');